import React, { useState } from "react";
import { Link } from "gatsby";
import twIcon from "../../img/twitter-icon.svg";
import fbIcon from "../../img/facebook-icon.svg";
import inIcon from "../../img/linkedin-icon.svg";

import "./style.sass";

const INACTIVE = "inactive";
const ACTIVE = "active";

const LinkMap = {
  home: {
    ref: "/",
    label: "Home"
  },
  about: {
    ref: "/about",
    label: "About Us"
  }
};

const Navbar = ({ display, currentPageUrl, navLink = "home" }) => {
  const [[shareButtonsState], setShareButtonsState] = useState([INACTIVE]);

  const shareClick = () => {
    return setShareButtonsState([
      shareButtonsState === ACTIVE ? INACTIVE : ACTIVE
    ]);
  };

  const linkDetails = LinkMap[navLink];

  return (
    <nav
      className={`navbar is-transparent ${display}`}
      role="navigation"
      aria-label="main-navigation"
    >
      <div className="container">
        <div className="nav-menu">
          <div className="navbar-start">
            {linkDetails && (
              <Link aria-label={linkDetails.label} to={linkDetails.ref}>
                {linkDetails.label}
              </Link>
            )}
          </div>
          <div className="navbar-end">
            <div
              aria-label="Share on social media"
              onClick={shareClick}
              className="share-trigger"
            >
              Share
            </div>
          </div>
        </div>

        <div className={`share-buttons ${shareButtonsState}`}>
          <a
            className="navbar-item"
            href={`https://www.facebook.com/sharer/sharer.php?u=${currentPageUrl}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="icon">
              <img src={fbIcon} alt="Share on Facebook" />
            </span>
          </a>

          <a
            className="navbar-item"
            href={`https://twitter.com/intent/tweet?url=${currentPageUrl}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="icon">
              <img src={twIcon} alt="Share on Twitter" />
            </span>
          </a>

          <a
            className="navbar-item"
            href={`https://www.linkedin.com/shareArticle?mini=true&url=${currentPageUrl}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="icon">
              <img src={inIcon} alt="Share on LinkedIn" />
            </span>
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
