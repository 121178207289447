import React from "react";

import "./style.sass";

const Footer = ({ display }) => {
  return (
    <footer className={`global-foot ${display}`}>
      <div className="container">
        <div className="top">
          <a
            href="https://www.100storybuilding.org.au/centre-for-young-writers/"
            target="_blank"
            rel="noopener noreferrer"
          >
            100storybuilding.org.au
          </a>
        </div>
        <div className="bottom">
          <div>92 Nicholson Street, Footscray VIC 3011</div>
          <div>Copyright © {new Date().getFullYear()} - 100 Story Building</div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
