import React from "react";
import Helmet from "react-helmet";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "./all.sass";
import useSiteMetadata from "./SiteMetadata";

const TemplateWrapper = ({
  children,
  pageName,
  currentPageUrl,
  showNavbar = true,
  navLink = "home"
}) => {
  const { title, description } = useSiteMetadata();
  return (
    <div className={`layout-wrapper${pageName ? ` ${pageName}` : ""}`}>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/img/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          href="/img/favicon-32x32.png"
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href="/img/favicon-16x16.png"
          sizes="16x16"
        />
        {/* <!-- Twitter Card data --> */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@100StoryB" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:creator" content="@100StoryB" />
        {/* <-- Twitter Summary card images must be at least 120x120px --> */}
        <meta name="twitter:image" content="/img/og-image.png" />

        {/* <!-- Open Graph data --> */}
        <meta property="og:title" content={title} />
        <meta property="og:type" content="business.business" />
        <meta property="og:url" content={currentPageUrl} />
        <meta property="og:image" content="/img/og-image.png" />
        <meta property="og:image:alt" content="100 Story Building" />
        <meta property="og:description" content={description} />
        <meta property="og:site_name" content="For Sale | 100 Story Building" />
      </Helmet>
      <Navbar
        currentPageUrl={currentPageUrl}
        navLink={navLink}
        display={showNavbar === true ? "visible" : "hidden"}
      />
      {children}
      <Footer display={showNavbar === true ? "visible" : "hidden"} />
    </div>
  );
};

export default TemplateWrapper;
